<template>
    <div class="live-feed-daily">
        <div class="daily-wrap">
            <SectionHeader v-bind:title="title"/>
            <div class="daily-container">
                <div class="player-splash">
                    <img src="https://via.placeholder.com/1920x1080" alt="" class="thumbnail">
                    <button class="join-meeting join-meeting--future">UPCOMING EVENT</button>
                </div>
            </div>
        </div>

        <!-- <div class="chat-wrap">
            <SectionHeader v-bind:title="titleChat"/>
            <Chat v-bind:callObject="callObject"/>
        </div> -->
    </div>
</template>
<script>
import SectionHeader from '@/components/snippets/SectionHeader.vue';
import moment from 'moment-timezone';
import $ from 'jquery';

var eventCountdown;

export default {
    name: 'LiveFeedDaily',
    components: { 
        SectionHeader,
    },

    props: {
        callObject: Object,
        eventStartDate: Object
    },

    data() {
        return {
            title: "UPCOMING Event",
            titleChat: "Chat",
        }
    },

    mounted() {
        var thumbnail = $('.daily-thumbnail > img').attr('src');
        $('.thumbnail').attr('src', thumbnail);


        console.log(this.eventStartDate);

        if (moment(this.eventStartDate)) {
            eventCountdown = setInterval(() => {
                var countDownDate   = moment(this.eventStartDate).add(1, 'minute').format('MM/DD/YYYY HH:mm');

                var countDownTime   = new Date(countDownDate).getTime();
                var now             = new Date().getTime();
                var timeLeft        = countDownTime - now;

                console.log(this.eventStartDate);
                console.log(countDownDate);
                console.log(timeLeft);

                if (timeLeft <= 300000) {
                    
                    var minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
                    var seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

                    if (minutes < 10) {
                        minutes = '0' + minutes;
                    }

                    if (seconds < 10) {
                        seconds = '0' + seconds;
                    }

                    $('.join-meeting--future').text("Event Live In: " + minutes + ":" + seconds);

                    if (parseInt(minutes) == 0 && parseInt(seconds) == 0) {
                        console.log("LIVE");
                        this.$router.go(0);
                    }
                }
            }, 1000);

        }
    },

    destroyed() {
        clearInterval(eventCountdown);
    },
}

</script>