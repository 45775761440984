<template>
    <div class="live-feed-daily">
        <div class="daily-wrap">
            <SectionHeader v-bind:title="title"/>
            <DailyContainer v-bind:callObject="callObject"/>
        </div>

        <div class="chat-wrap">
            <SectionHeader v-bind:title="titleChat"/>
            <img id="chat-slider" src="../assets/img/icon_slide.svg" alt="">
            <Chat v-bind:callObject="callObject"/>
        </div>
    </div>
</template>
<script>
import SectionHeader from '@/components/snippets/SectionHeader.vue';
import DailyContainer from '@/components/snippets/DailyContainer.vue';
import Chat from '@/components/Chat.vue';
import $ from 'jquery';

export default {
    name: 'LiveFeedDAily',
    components: { 
        SectionHeader,
        DailyContainer,
        Chat
    },

    props: {
        callObject: Object
    },

    data() {
        return {
            title: "Live Feed",
            titleChat: "Chat",
        }
    },

    mounted() {
        $('#chat-slider').click(function (e) { 
            e.preventDefault();

            if ($(this).hasClass('active')) {
                $('.daily-wrap').removeClass('daily-wrap--fw');
                $('.chat-wrap').find('.section-header').removeClass('section-header--shrunk');
                $('.chat').removeClass('chat--hidden');
                $(this).removeClass('shrunk');
                $(this).removeClass('active');

            } 
            
            else {
                $('.daily-wrap').addClass('daily-wrap--fw')
                $('.chat-wrap').find('.section-header').addClass('section-header--shrunk');
                $('.chat').addClass('chat--hidden');
                $(this).addClass('shrunk');
                $(this).addClass('active');
            }
            
        });
    },
    

}
</script>