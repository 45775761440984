<template lang="">
    <div class="chat">
        <div class="chat-area custom-scrollbar" id="chat-area">
        </div>

        <div class="chat-box">
            <img id="chat-user-profile" src="https://via.placeholder.com/100" alt="profile">
            <textarea class="custom-scrollbar"  name="chat" id="chat-input" maxlength="500" rows="1" placeholder="Type here to write..."></textarea>
            <img id="chat-send" src="../assets/img/icon_send.svg" alt="Send">
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
import moment from 'moment'

export default {
    name: 'Chat',

    components: {
    },

    props: {
        callObject: Object
    },

    data() {
        return {

        }
    },

    mounted() {
        const call      = this.$props.callObject;
        var userImg     = $('.user-nav').find('img').attr('src');

        $('#chat-user-profile').attr('src', userImg);

        call.on('app-message', (event) => {

            var jsonObj = JSON.parse(event.data.message); 
            var date    = event.data.date;
            var time    = moment(date).format('LT'); 

            console.log("Message Recieved: ");
            console.log(jsonObj)


            // This creates a chat-item div with two cols, first with img and second with text
            $('.chat-area').append($('<div class="chat-item"></div>')
                .append($('<div class="col"></div>').append($('<img src="' + jsonObj[1] + '" alt="profile">')))
                .append($('<div class="col"></div>')
                .append($('<h2 class="chat-name">' + jsonObj[0] + '</h2>'))
                .append($('<p class="chat-time">' + time + '</p>'))
                .append($('<p class="chat-msg">' + jsonObj[2] + '</p>'))));

            $('.chat-area').animate({scrollTop: 10000});
        });

        $('#chat-send').click(function (e) { 
            e.preventDefault();
            var message = $('#chat-input').val();

            if (message.length > 0) {
                sendChat(message);
            }

        });

        $('#chat-input').on('keypress', function(e) {
            
            var message = $('#chat-input').val();

            if (message.length > 0 && e.key == "Enter") {
                e.preventDefault();
                sendChat(message);
            }
        });

        function sendChat(message) {

            var userElm     = $('.user-nav');
            var username    = userElm.attr('data-user-fullname');
            var userImg     = userElm.find('img').attr('src');
            var time        = moment().format('LT'); 

            var jsonString = JSON.stringify([username, userImg, message])

            console.log("Sending...");
            call.sendAppMessage({message: jsonString});

            $('#chat-input').val("");

            $('.chat-area').append($('<div class="chat-item"></div>')
            .append($('<div class="col"></div>').append($('<img src="' + userImg + '" alt="profile">')))
            .append($('<div class="col"></div>')
            .append($('<h2 class="chat-name">' + username + '</h2>'))
            .append($('<p class="chat-time">' + time + '</p>'))
            .append($('<p class="chat-msg">' + message + '</p>'))));

            $('.chat-area').animate({scrollTop: 10000});
        }
    },
}



</script>