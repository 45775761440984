<template>
    <div class="daily-container">
        <div class="lds-ring" id="daily-loader">
            <img class="loading-gif" src="../../assets/img/loadingwheel.gif" alt="">
        </div>
        <div class="player-splash">
            <img src="https://via.placeholder.com/1920x1080" alt="" class="thumbnail">
            <button class="join-meeting">JOIN LIVE EVENT</button>
        </div>
        <div class="player">
            <div class="top-menu">
                <div class="player-menu-item" id="player-settings">
                    <img src="../../assets/img/daily_icons/default_cog.svg" alt="settings">
                    <span>SETTINGS</span>
                </div>

                <div class="player-menu-item player-menu-item--center" id="player-people">
                    <img src="../../assets/img/daily_icons/default_person.svg" alt="people">
                    <span>PEOPLE</span>
                </div>
                <div></div>

                <!-- <div class="player-menu-item player-menu-item--center player-menu-view" id="player-highlight">
                    <img src="../../assets/img/daily_icons/default_speaker_top.svg" alt="highlight">
                    <span>HIGHLIGHT</span>
                </div> -->

                <div class="player-menu-item player-menu-item--center player-menu-view active" id="player-group">
                    <img src="../../assets/img/daily_icons/default_speaker_all.svg" alt="group">
                    <span>GROUP</span>
                </div>

                <div class="player-menu-item player-menu-item--center player-menu-view" id="player-speaker">
                    <img src="../../assets/img/daily_icons/default_speaker_main.svg" alt="speaker">
                    <span>SPEAKER</span>
                </div>

                <div class="player-menu-item player-menu-item--center" id="player-fullscreen">
                    <img class="default" src="../../assets/img/daily_icons/default_maximise.svg" alt="maximise">
                    <img class="active" src="../../assets/img/daily_icons/active_minimise.svg" alt="minimise" style="display:none;">
                    <span>MAXIMISE</span>
                </div>
            </div>


                <div class="daily-notification-popup">
                    <p>CAMERA AND/OR MIC ARE NOT DETECTED</p>
                    <img class="daily-notification-popup-close" src="../../assets/img/daily_icons/default_close.svg" alt="">
                </div>
            <div class="feeds" id="video-feeds">
                <!-- TODO: Add permanent screenshare  -->
            </div>

            <div id="audio-feeds">
                
            </div>

            <div class="settings-menu">
                <p>Camera</p>

                <select name="settings-camera" id="settings-camera">
                </select>

                <p>Microphone</p>

                <select name="settings-mic" id="settings-mic">
                </select>

                <p>Speakers</p>

                <select name="settings-speakers" id="settings-speakers">
                </select>
            </div>

            <div class="settings-menu-close"></div>

            
            <div class="people-menu">
                <p>PEOPLE IN CHAT</p>
                <div class="people-divider"></div>

                <div class="people">
                    <div class="call-user">
                        <p class="pfp-initials unselectable">Y</p>
                        <p>- You</p>
                    </div>
                </div>
            </div>

            <div class="people-menu-close"></div>


            <div class="bottom-menu">
                <div class="player-menu-item player-menu-item--center" id="player-cam">
                    <img class="default" src="../../assets/img/daily_icons/default_camera.svg" alt="turn off">
                    <img class="active" src="../../assets/img/daily_icons/active_camera_on.svg" alt="turn on" style="display:none;" >
                    <span>TURN OFF</span>
                </div>

                <div class="player-menu-item" id="player-mic">
                    <img class="default" src="../../assets/img/daily_icons/default_mic.svg" alt="mute">
                    <img class="active" src="../../assets/img/daily_icons/active_mic.svg" alt="unmute" style="display:none;">
                    <span>MUTE</span>
                </div>

                <!-- <div class="player-menu-item" id="player-more">
                    <img class="default" src="../../assets/img/daily_icons/default_more.svg" alt="more">
                    <span>MORE</span>
                </div> -->

                <div class="player-dropdown" :class="{ 'player-dropdown--2col' : this.$data.userRole == 'moderator'}" >
                    <div v-if="this.$data.userRole == 'moderator'" class="player-menu-item player-menu-item--center" id="player-record">
                        <img class="default" src="../../assets/img/daily_icons/default_rec.svg" alt="record">
                        <img class="active" src="../../assets/img/daily_icons/active_rec.svg" alt="record" style="display:none;">
                         <span>RECORD</span>
                    </div>

                    <div class="player-menu-item player-menu-item--center" id="player-share">
                        <img class="default" src="../../assets/img/daily_icons/default_share.svg" alt="share">
                        <img class="active" src="../../assets/img/daily_icons/active_share_stop.svg" alt="stop share" style="display:none;">
                        <span>SHARE</span>
                    </div>

                </div>


                <div class="player-menu-item" id="player-leave">
                    <img src="../../assets/img/daily_icons/default_close.svg" alt="leave">
                    <span>LEAVE</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import store from '@/store/index.js'
import $ from 'jquery';

export default {
    name: 'DailyContainer',

    props: {
        callObject: Object,
    },

    data() {
        return {
            userRole: "moderator"
        }
    },

    unmounted() {
        console.log("unmount");
    },

    destroyed() {
        const call = this.$props.callObject;

        call.leave();
        layoutReset();
        updateTotalParticipants();
        console.log("destroyed");
    },

    mounted() {

        var vue = this;

        var user_id = "";

        var participant     = false;
        var camToggled      = true;
        var micToggled      = true;
        var screenSharing   = false;
        var recording       = false;
        var activeSpeaker   = '';
 
        var totalParticipants = 0;
        
        var cameraDeviceList        = [];
        var microphoneDeviceList    = [];
        var speakerDeviceList       = [];


        // Check to prevent screenShare off 
        var screenShareEvent = false;
        var screenShareInProgress = false;

        // Modes = 'grid', 'highlight', 'speaker', 'fullscreen' 
        var viewMode = 'grid';

        const call = vue.$props.callObject;
        const ROOM_URL = vue.$props.callObject.properties.url;

        var thumbnail = $('.daily-thumbnail > img').attr('src');
        var username = $('.user-nav').attr('data-user-fullname');

        $('.thumbnail').attr('src', thumbnail);


        $('.join-meeting').click(function (e) { 
            e.preventDefault();

            participant     = false;
            camToggled      = true;
            micToggled      = true;
            screenSharing   = false;
            activeSpeaker   = '';

            console.log("Joining...");

            console.log(vue.$data.userRole);

            if (vue.$data.userRole == "moderator") {
                var xhr     = new XMLHttpRequest();
                var data    = `{"properties" : {"is_owner":true}}`;
           
                xhr.open("POST", 'https://api.daily.co/v1/meeting-tokens');
                xhr.setRequestHeader("Content-Type", "application/json");
                xhr.setRequestHeader("Authorization", "Bearer 260a34e726ede2a1b6eb89c6faf6fbd9aecae14abcb1fd65a049ca1904a0bcf8");
                xhr.send(data);

                xhr.onreadystatechange = function () {
                    if (xhr.readyState === 4) {
                        console.log("Token generated");
                        console.log(xhr.status);
                        console.log(xhr.responseText);

                        var responseObject = JSON.parse(xhr.responseText);

                        call.join({ url: ROOM_URL, token: responseObject.token });
                    }
                };
            } 
            
            else {
                call.join({ url: ROOM_URL});
            }


            $(this).fadeOut(300);
            $('.player-splash img').fadeOut(300);

            setTimeout(() => {
                $('#daily-loader').fadeIn(300);
            }, 300);
        });

        function layoutReset() {
            console.log("layout reset");
            updateTotalParticipants();
            // Reset icons

            $('#player-fullscreen').find('.active').css('display', 'none');
            $('#player-fullscreen').find('.default').css('display', 'block');
            $('#player-fullscreen').find('span').text('FULL SCREEN');

            $('#player-mic').find('.active').css('display', 'none');
            $('#player-mic').find('.default').css('display', 'block');
            $('#player-mic').find('span').text('MUTE');

            $('#player-cam').find('.active').css('display', 'none');
            $('#player-cam').find('.default').css('display', 'block');
            $('#player-cam').find('span').text('TURN OFF');

            if (viewMode == 'fullscreen') {
                $('body').css('overflow-y', 'scroll');
                $('.daily-container').removeClass('daily-container--fullScreen');
                $('.feeds').removeClass('feeds--fullScreen'); 
            }

        }

        call.on('joined-meeting', (event) => {
            console.log('Joined');

            layoutReset();
            updateTotalParticipants();

            $('.player-splash').fadeOut(300);
            $('#daily-loader').fadeOut(300);
            
            setTimeout(() => {
                $('.live-feed-daily').addClass('live-feed-daily--active');
                $('.player').fadeIn(300);
                $('.chat-wrap').fadeIn(300);
            }, 300);
            
            call.enumerateDevices().then(
                function(value) {
                    console.log("Devices retrieved");

                    updateDeviceList(value);
                },

                function(error) {
                    console.log("Device error");
                    console.log(error);
                }
            );

            var name = this.userID + ":" + event.participants.local.user_id;
            user_id = event.participants.local.user_id;

            console.log(name);

            if (participant) {
                participant.user_id = user_id;
            }

            call.setUserName(username, { thisMeetingOnly: true });
        });



        call.on('left-meeting', () => {
            console.log("Left meeting");
            updateTotalParticipants();

            $('#video-' + user_id).remove();
            $('#audio-' + user_id).remove();

            $('#screen-video-' + user_id).remove();
            $('#screen-audio-' + user_id).remove();

            call.stopScreenShare();
        })

        call.on('participant-joined', () => {
            console.log('Participant joined');

            updateTotalParticipants();
            console.log("Participants: " + totalParticipants);
        });

        call.on('participant-updated', (event) => {
            console.log('Participant updated');

            if (!participant) {
                participant = event.participant;
            }
            
            var p = event.participant;

            var users = call._participants;

            console.log(users);

            for (let key in users) {
                console.log("User screen");
                console.log(users[key].screen);

                if (users[key].screen && !screenShareInProgress) {
                    console.log("sharing");
                    screenShareInProgress = true;
                    $('#player-speaker').trigger('click');
                    handleScreenShare(users[key].user_id, users[key].screenVideoTrack, users[key].screenAudioTrack, false);
                    return false;
                } else if (!users[key].screen) {
                    console.log("not sharing");
                    screenShareInProgress = false;
                    // clearScreenShare();
                }
            }

            if (!screenShareInProgress) {
                clearScreenShare();
            }




            if (p.video && !screenShareEvent) {
                console.log("Video on");

                $('#username-' + p.user_id).css('display', 'none');
                mediaStreamTracksHandler(p.user_id, p.user_name, p.videoTrack, p.audioTrack, p.local);
            } else if (!screenShareEvent) {
                console.log("Video off");
                // mediaStreamTracksHandler(p.user_id, p.user_name, p.videoTrack, p.audioTrack, p.local);


                console.log("show name");
                $('#username-' + p.user_id + ' > p ').text(p.user_name);
                $('#username-' + p.user_id).css('display', 'block');
    
            }

            if (p.screen  && screenShareEvent) {
                screenShareEvent = false;
                screenSharing = true;
                handleScreenShare(p.user_id, p.screenVideoTrack, p.screenAudioTrack, p.local);
                
                $('#player-speaker').trigger('click');
            } else if (screenShareEvent) {
                console.log("Screenshare off");
                screenShareEvent = false;
                screenSharing = false;
                clearScreenShare();
                $('#player-speaker').trigger('click');
            }

        });

        call.on('participant-left', (event) => {
            console.log('Participant left');

            updateTotalParticipants();
            
            var id = event.participant.user_id;

            $('#feed-' + id).remove();
            $('#audio-' + id).remove();

            if ($('#feed-share-' + id).length > 0) {
                clearScreenShare();
                $('#feed-share-' + id).remove();
                $('#screen-audio-' + id).remove();
            }


        });

        call.on('active-speaker-change', (event) => {
            var id = event.activeSpeaker.peerId;
            activeSpeaker = id;

            switch (viewMode) {
                case 'grid':
                    break;

                case 'highlight':
                    highlightMode(id);
                    break;
            
                case 'speaker':
                    speakerMode(id);
                    break;

                default:
                    break;
            }
        });

        // ERRORS

        $('.daily-notification-popup-close').click(function (e) { 
            e.preventDefault();
            
            $('.daily-notification-popup').fadeOut(300);
        });

        call.on('camera-error', (event) => {
            console.log("Camera error");
            console.log(event);

            $('.daily-notification-popup').fadeIn(300);

            if (event.error) {
                switch (event.error.type) {
                    case "cam-in-use":
                        console.log("case 1");
                        $('.daily-notification-popup > p').text('Apologies, your camera is in use by another piece of software. Please close the other software, then leave and rejoin the event.')
                        break;

                    case "mic-in-use":
                        console.log("case 2");
                        $('.daily-notification-popup > p').text('Apologies, your microphone is in use by another piece of software. Please close the other software, then leave and rejoin the event.')
                        break;

                    case "cam-mic-in-use":
                        console.log("case 3");
                        $('.daily-notification-popup > p').text('Apologies, your camera/microphone is in use by another piece of software. Please close the other software, then leave and rejoin the event.')
                        break;
                
                    default:
                        console.log("case default 1");
                        break;
                }
            } else {
                switch (event.errorMsg.errorMsg) {
                    case "not allowed":
                        console.log("case 4");
                        $('.daily-notification-popup > p').text('THE BROWSER HAS BLOCKED ACCESS TO THE CAMERA AND/OR MIC')
                        break;

                    case "devices error":
                        console.log("case 5");
                        $('.daily-notification-popup > p').text('CAMERA AND/OR MIC ARE NOT DETECTED')
                        break;

                    case "camera/mic error":
                        console.log("case 6");
                        $('.daily-notification-popup > p').text('SOMETHING WENT WRONG, PLEASE TRY AGAIN')
                        break;
                
                    default:
                        console.log("case default 2");
                        break;
                }
            }
        });

        call.on('live-streaming-started', () => {
            console.log('Live Streaming Started...');

            if ($('#player-record').length) {
                $('#player-record').find('.active').css('display', 'block');
                $('#player-record').find('.default').css('display', 'none');
                $('#player-record').find('span').text('RECORDING');
    
            }

            recording = true;
        });

        call.on('live-streaming-stopped', () => {
            console.log('Live Streaming Stopped');

            if ($('#player-record').length) {
                $('#player-record').find('.active').css('display', 'none');
                $('#player-record').find('.default').css('display', 'block');
                $('#player-record').find('span').text('RECORD');
            }
            
            recording = false;
        });

        call.on('live-streaming-error', (event) => {
            console.log('Live Streaming Error');
            console.log(event.errorMsg);

            $('.daily-notification-popup').fadeIn(300);
            $('.daily-notification-popup > p').text(event.errorMsg)

        });

        // FUNCITONS

        var numberOfRows = 3;

        function mediaStreamTracksHandler(id, username, video, audio, local) {
            console.log(`Screen Sharing: ${screenSharing} : ${username}`);

            if (!$('#video-' + id).length) {

                if (screenSharing || screenShareInProgress) {
                    $('#video-feeds').append($('<div class="video-wrap video-wrap--float" id="feed-' + id + '"></div>')
                    .append('<video autoplay muted playsinline style="pointer-events: none;" src="" id="video-' + id + '"></video>')
                    .append('<div class="username-wrap" id="username-' + id + '"><p >' + username + '</p></div>')); 
                } else {
                    $('#video-feeds').append($('<div class="video-wrap" id="feed-' + id + '"></div>')
                    .append('<video autoPlay muted playsInline src="" id="video-' + id + '"></video>')
                    .append('<div class="username-wrap" id="username-' + id + '"><p >' + username + '</p></div>'));
                }
            }

            if (!$('#audio-' + id).length && !local) {
                $('#audio-feeds').append('<audio autoPlay src="" id="audio-' + id + '"></audio>');
            }

            var videoElm = document.getElementById('video-' + id);
            videoElm.srcObject = new MediaStream([video]);

            if (!local) {
                var audioElm = document.getElementById('audio-' + id);
                audioElm.srcObject = new MediaStream([audio]);
            }

            var videoFeeds = $('.video-wrap').length;

            //TODO Test using single display mode, either flex or grid / or center grid and reset when appropiate
            if (videoFeeds > 4) {
                $('#video-feeds').css({
                    'display': 'grid',
                    'grid-template-columns': 'repeat(' + numberOfRows + ', 1fr)'
                });

                if (isSquare(videoFeeds)) {
                    numberOfRows++;
                }
            } else {
                $('#video-feeds').css({
                    'display': 'flex',
                    'grid-template-columns': 'unset'
                });
            }

            // if (totalParticipants == 3) {
            //     $('.video-wrap').css('height', '50%');
            // } else {
            //     $('.video-wrap').css('height', 'auto');
            // }
        }

        function isSquare (n) {
            return n > 0 && Math.sqrt(n) % 1 === 0;
        }

        function handleScreenShare(id, video, audio, local) {

            //TODO Use permanent screenshare div, pass just id and audio/video feed

            $('.video-wrap').addClass('video-wrap--float');
            $('.video-wrap--share').removeClass('video-wrap--float');

            if (!$('#screen-video-' + id).length) {
                $('#video-feeds').append($('<div class="video-wrap video-wrap--share" id="feed-share-' + id + '"></div>')
                .append('<video class="screen-share" autoplay muted playsinline style="pointer-events: none;" src="" id="screen-video-' + id + '"></video>'));
            }

            if (!$('#screen-audio-' + id).length && !local) {
                $('#audio-feeds').append('<audio class="screen-share" autoPlay src="" id="screen-audio-' + id + '"></audio>')
            }

            var videoElm = document.getElementById('screen-video-' + id);
            videoElm.srcObject = new MediaStream([video]);

            if (!local) {
                var audioElm = document.getElementById('screen-audio-' + id);
                audioElm.srcObject = new MediaStream([audio]);
            }
        }

        function clearScreenShare() {
            screenShareInProgress = false;
            screenSharing = false;
            $('.video-wrap--share').remove();
            $('.video-wrap').removeClass('video-wrap--float');

            $('#player-share').find('.active').css('display', 'none');
            $('#player-share').find('.default').css('display', 'block');
            $('#player-share').find('span').text('SCREEN SHARE');
        }

        function updateTotalParticipants() {
            var users = call.participants();

            totalParticipants = 0;

            for (let key in users) {
                console.log("user plus");
                totalParticipants++;
            }

            if (totalParticipants == 1) {
                $('#player-people').find('span').text('1 PERSON')
            } else {
                $('#player-people').find('span').text(totalParticipants + ' PEOPLE')
            }
        }

        function updateDeviceList(data) {

            $('#settings-mic').empty();
            $('#settings-camera').empty();
            $('#settings-speakers').empty();

            for (let i = 0; i < data.devices.length; i++) {
                const device = data.devices[i];
                
                switch (device.kind) {
                    case "audioinput":
                            microphoneDeviceList.push(device);
                            $('#settings-mic').append('<option value="' + device.deviceId + '">' + device.label + '')
                        break;
                    
                    case "videoinput":
                            cameraDeviceList.push(device);
                            $('#settings-camera').append('<option value="' + device.deviceId + '">' + device.label + '')
                        break;

                    case "audiooutput":
                            speakerDeviceList.push(device);
                            $('#settings-speakers').append('<option value="' + device.deviceId + '">' + device.label + '')
                        break;
                
                    default:
                        break;
                }
            }
        }

        function gridMode() {
            console.log(`screen share: ${screenSharing}, progress: ${screenShareInProgress}`);

            var videoFeeds = $('.video-wrap').length;

            $('.video-wrap').removeClass('video-wrap--speaking');
            $('.video-wrap').css('display', 'flex');

            if (!screenShareInProgress) {
                if (videoFeeds > 4) {
                    $('#video-feeds').css('display', 'grid');
                } else {
                    $('#video-feeds').css('display', 'flex');
                }
            } else {
                $('#video-feeds').css('display', 'flex');
                $('.video-wrap').toggleClass('video-wrap--share-grid');
            } 
        }

        function speakerMode(id) {
            $('#video-feeds').css('display', 'flex');

            var videoFeeds = $('.video-wrap').length;

            if (videoFeeds > 1) {
                if (screenSharing || screenShareInProgress) {
                    $('.video-wrap').removeClass('video-wrap--share-grid');
                    $('.video-wrap--float').css('display', 'none');
                } else {
                    console.log("not screen sharing");
                    $('.video-wrap').css('display', 'none');
                    $('.video-wrap--share').css('display', 'block');

                    $('.video-wrap').removeClass('video-wrap--share-grid');
                }
    
                console.log("show feed");
                $('.video-wrap').removeClass('video-wrap--speaking');
                $('#feed-' + id).css('display', 'flex');
                $('#feed-' + id).addClass('video-wrap--speaking');
                
            } else if (screenShareInProgress) {
                if ($('.video-wrap').hasClass('video-wrap--share-grid')) {
                    
                    $('.video-wrap').toggleClass('video-wrap--share-grid');
                }
            }
        }

        function highlightMode(id) {
            // $('.highlight-video video').detatch().appendTo('.sub-grid');

            // $('#video-feeds video').detatch().appendTo('.sub-grid');
            // $('#video-feeds').css('display', 'none');

            // $('#video-feeds-highlight').css('display', 'block');

            // $('#video-' + id).appendTo('.highlight-video');

            console.log(id);
        }

        // EVENTS

        $('#player-leave').click(function (e) { 
            e.preventDefault();

            console.log("Leaving...");
            call.leave();

            var id = participant.user_id;

            $('#feed-' + id).remove();
            $('#feed-' + id).remove(); // Check for duplicates


            $('.daily-container').removeClass('daily-container--fullScreen');
            $('.feeds').removeClass('feeds--fullScreen');
            

            $('.player').fadeOut(300);
            $('.chat-wrap').fadeOut(300);

            setTimeout(() => {
                $('.live-feed-daily--active').removeClass('live-feed-daily--active');
                $('.player-splash').fadeIn(300);
                $('.player-splash img').fadeIn(300);
                $('.join-meeting').fadeIn(300);
            }, 300);
        });

        $('#player-cam').click(function (e) { 
            e.preventDefault();
            var id = participant.user_id;

            if (camToggled) {

                call.updateParticipant(id, {
                    setVideo: false
                })

                $('#player-cam').find('span').text('TURN ON')
                $(this).find('.default').css('display', 'none');
                $(this).find('.active').css('display', 'block');

                camToggled = false;
            } else {
                call.updateParticipant(id, {
                    setVideo: true
                })

                $('#player-cam').find('span').text('TURN OFF')
                $(this).find('.active').css('display', 'none');
                $(this).find('.default').css('display', 'block');

                camToggled = true;
            }
        });

        $('#player-mic').click(function (e) { 
            e.preventDefault();

            var id = participant.user_id;

            if (micToggled) {
                call.updateParticipant(id, {
                    setAudio: false
                })

                $('#player-mic').find('span').text('UNMUTE')
                $(this).find('.default').css('display', 'none');
                $(this).find('.active').css('display', 'block');

                micToggled = false;
            } else {
                call.updateParticipant(id, {
                    setAudio: true
                })

                $('#player-mic').find('span').text('MUTE')
                $(this).find('.active').css('display', 'none');
                $(this).find('.default').css('display', 'block');

                micToggled = true;
            }
        });

        $('#player-more').click(function (e) { 
            e.preventDefault();

            var dropdown = $('.bottom-menu > .player-dropdown');

            if (dropdown.hasClass('show')) {
                dropdown.animate({height:0},250).removeClass('show');
            } else {
                dropdown.animate({height:230},250).addClass('show');
            }
        });

        $('#player-share').click(function (e) { 
            e.preventDefault();

            screenShareEvent = true;

            console.log(screenSharing);
            if (screenSharing) {
                call.stopScreenShare();
                screenSharing = false;

                $('.screen-share').remove();
                    
                $(this).find('.active').css('display', 'none');
                $(this).find('.default').css('display', 'block');
                $(this).find('span').text('SCREEN SHARE');
            } else {
                if (!screenShareInProgress) {
                    call.startScreenShare();
                    screenSharing = true;
    
                    $(this).find('.default').css('display', 'none');
                    $(this).find('.active').css('display', 'block');
                    $(this).find('span').text('STOP SHARE');
                }
            }
                
            
        });

        $('#player-people').click(function (e) { 
            e.preventDefault();
            
            if ($(this).hasClass('active')) {
                $('.people-menu').slideUp(300);
                $(this).removeClass('active');
                $('.people-menu-close').css('display', 'none');
            } else {
                var users = call._participants;

                $('.people-menu > .people').html('');
                $('.people-menu > .people').append($('<div class="call-user">')
                .append('<p class="pfp-initials unselectable">Y</p>')
                .append('<p>YOU</p>'));

                for (let key in users) {
                    // console.log(key, users[key]);

                    var inititlas = getInitials(users[key].user_name);

                    if (key != 'local') {
                        // console.log(users[key].user_name);
                        $('.people-menu > .people').append($('<div class="call-user">')
                        .append('<p class="pfp-initials unselectable">' + inititlas + '</p>')
                        .append('<p>' + users[key].user_name + '</p>'));
                    }
                }

                $('.people-menu').slideDown(300);
                $(this).addClass('active');

                $('.people-menu-close').css('display', 'block');
            }
        });

        $('.people-menu-close').click(function (e) { 
            e.preventDefault();

            $('.people-menu').slideUp(300);
            $('#player-people').removeClass('active');
            $(this).css('display', 'none');
        });

        $('#player-record').click(function (e) { 
            e.preventDefault();
            
            if (recording) {
                call.stopLiveStreaming();
            } 

            else {
                call.startLiveStreaming({rtmpUrl: 'rtmp://92.205.59.205/live/stream'});
            }
        });

        // SETTINGS

        $('#player-settings').click(function (e) { 
            e.preventDefault();
            
            if ($(this).hasClass('active')) {
                $('.settings-menu').slideUp(300);
                $(this).removeClass('active');
            } else {
                $('.settings-menu').slideDown(300);
                $(this).addClass('active');
                $('.settings-menu-close').css('display', 'block');

                call.enumerateDevices().then(
                    function(value) {
                        console.log("Devices retrieved");

                        updateDeviceList(value);
                    },

                    function(error) {
                        console.log("Device error");
                        console.log(error);
                    }
                );
            }
        });

        $('.settings-menu-close').click(function (e) { 
            e.preventDefault();

            $('.settings-menu').slideUp(300);
            $('#player-settings').removeClass('active');
            $(this).css('display', 'none');
        });

        $('#settings-camera').on('change', function() {
            // console.log( this.value );

            var id = this.value;

            call.setInputDevicesAsync({
                videoDeviceId: id
                }).then(
                function() {
                    console.log("Devices switched");
                },

                function(error) {
                    console.log("Device error");
                    console.log(error);
                }
            );
        });

        $('#settings-mic').on('change', function() {
            // console.log( this.value );

            var id = this.value;

            call.setInputDevicesAsync({
                audioDeviceId: id
                }).then(
                function() {
                    console.log("Devices switched");
                },

                function(error) {
                    console.log("Device error");
                    console.log(error);
                }
            );
        });



        $('#settings-speakers').on('change', function() {
            // console.log( this.value );

            var id = this.value;

            call.setOutputDevice({
                outputDeviceId: id
            });
        });

        // VIEWS

        $('#player-group').click(function (e) { 
            e.preventDefault();
            
            $('.player-menu-view').removeClass('active');
            $(this).addClass('active');

            viewMode = 'grid';
            gridMode();
            
        });

        $('#player-speaker').click(function (e) { 
            e.preventDefault();
            
            $('.player-menu-view').removeClass('active');
            $(this).addClass('active');

            viewMode = 'speaker';
            // console.log(viewMode);

            speakerMode(activeSpeaker);
        });

        $('#player-highlight').click(function (e) { 
            e.preventDefault();
            
            $('.player-menu-view').removeClass('active');
            $(this).addClass('active');

            viewMode = 'highlight';
            // console.log(viewMode);

            highlightMode(participant.user_id);
        });

        $('#player-fullscreen').click(function (e) { 
            e.preventDefault();

            viewMode = 'fullscreen';

            $('.daily-container').toggleClass('daily-container--fullScreen');
            $('.feeds').toggleClass('feeds--fullScreen');

            if ($('.feeds').hasClass('feeds--fullScreen')) {
                $('body').css('overflow-y', 'hidden');
                $(this).find('.default').css('display', 'none');
                $(this).find('.active').css('display', 'block');
                $(this).find('span').text('MINIMISE');

                $('.section-header').addClass('hidden');
                $('#chat-slider').addClass('hidden');
            } else {
                $('body').css('overflow-y', 'scroll');
                $(this).find('.active').css('display', 'none');
                $(this).find('.default').css('display', 'block');
                $(this).find('span').text('FULL SCREEN');

                $('.section-header').removeClass('hidden');
                $('#chat-slider').removeClass('hidden');
            }
        });

        function getInitials(name) {
            let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

            let initials = [...name.matchAll(rgx)] || [];

            initials = (
            (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
            ).toUpperCase();

            return initials
        }
    },


}
</script>