<template>
    <div class="product-feed-wrap">
        <SectionHeader v-bind:title="title" v-bind:link="link"/>

        <input v-on:input="search($event.target.value)" type="text" name="search" id="search" placeholder="SEARCH">
        <img class="icon-search" src="../assets/img/icon_search.svg">
    
        <div v-if="isFetching" class="lds-ring" style="padding-top: 40px;">
            <img class="loading-gif" src="../assets/img/loadingwheel.gif" alt="">
        </div>
        <div v-else class="wrap">
            <div v-if="!isSearching" class="product-feed custom-scrollbar">
                <div class="product" v-for="(product, item) in this.products" v-bind:key="item">
                    <div class="col">
                        <img @click="goToProduct(product.slug, product.productId, product.producer.exhibitorId)" :src="product.bottleImage.fluid.src" alt="product">
                    </div>
                    <div class="col">
                        <h2  @click="goToProduct(product.slug, product.productId, product.producer.exhibitorId)" class="product-title">{{product.shortName}}</h2>
                        <h2 class="product-category">{{product.productCategory.name}} / {{product.producer.name}}</h2>

                        <p v-if="product.description" class="product-desc">{{shorten(product.description, 130, true)}}</p>
                        <a @click="goToProduct(product.slug, product.productId, product.producer.exhibitorId)">READ MORE</a>
                    </div>
                </div>
            </div>

            <div v-else class="product-feed custom-scrollbar">
                <div class="product" v-for="(product, item) in this.results" v-bind:key="item">
                    <div class="col">
                        <img @click="goToProduct(product.slug, product.productId, product.producer.exhibitorId)" :src="product.bottleImage.fluid.src" alt="product">
                    </div>
                    <div class="col">
                        <h2  @click="goToProduct(product.slug, product.productId, product.producer.exhibitorId)" class="product-title">{{product.shortName}}</h2>
                        <h2 class="product-category">{{product.productCategory.name}} / {{product.producer.name}}</h2>

                        <p v-if="product.description" class="product-desc">{{shorten(product.description, 130, true)}}</p>
                        <a @click="goToProduct(product.slug, product.productId, product.producer.exhibitorId)">READ MORE</a>
                    </div>
                </div>

                <div v-if="noResults && allLoadedSearch" class="no-results">
                    <p>NO RESULTS FOUND</p>
                </div>
            </div>

            <div class="load-more">
                <router-link :to="{ name: 'wines' }">
                    <p>SEE MORE</p>
                </router-link>
            </div>
        </div>

    </div>
</template>
<script>
import SectionHeader from '@/components/snippets/SectionHeader.vue'
import gql from 'graphql-tag'
import Fuse from 'fuse.js'


export default {
    name: 'ProductFeed',
    components: { 
        SectionHeader,
    },

    data() {
        return {
            title: "Wines",
            link: "wines",
            itemsPerPage: 8,
            itemsPerPageSearch: 8,
            isFetching: true,
            isSearching: false,
            fetched: false,
            searched: false,
            allLoaded: false,
            allLoadedSearch: false,
            noResults: false,
            products: [],
            productsAll: [],
            results: [],
            resultsAll: []
        }
    },

    props: {
        wineListId: String
    },

    methods: {
        goToProduct(slug, productId, exhibitorId) {
            let routeData = this.$router.resolve({name:'product', params:{slug:slug, productId:productId, exhibitorId:exhibitorId}});
            window.open(routeData.href, '_blank');
        },
        shorten(str, n, useWordBoundary) {
            if (str.length <= n) { return str; }
            const subString = str.substr(0, n-1);
            return (useWordBoundary 
                ? subString.substr(0, subString.lastIndexOf(" ")) 
                : subString) + "..."
        },
        search(string) {
            console.log("Searching");

            this.allLoadedSearch = false;
            this.$data.noResults = false

            if (string == "") {
                this.isSearching = false
                return false
            }

            var options;

            if (!isNaN(string)) {
                options = {
                    includeScore: true,
                    threshold: 0.0,
                    keys: [
                        'vintage'
                    ]
                }
            } else {
                options = {
                    includeScore: true,
                    threshold: 0.3,
                    keys: [
                        'shortName', 
                        'vintage',
                        'designation',
                        'wineColor',
                        'wineType',
                        'producer.name',
                        'grapeVarieties.varietyName',
                        {
                            name: 'country',
                            weight: 0.5
                        },
                        {
                            name: 'region',
                            weight: 0.5
                        },
                        {
                            name: 'subregion',
                            weight: 0.5
                        },
                        {
                            name: 'description',
                            weight: 0.2
                        }
                    ]
                }
            }

            this.isSearching = true


            const fuse = new Fuse(this.$data.productsAll, options)
            const result = fuse.search(string)

            this.$data.results = []
            this.$data.resultsAll = []

            for (let i = 0; i < result.length; i++) {
                const obj = result[i];

                if (obj.item.bottleImage !== null) {
                    this.$data.resultsAll.push(obj.item)
                }
            }

            if (this.$data.resultsAll.length == 0) {
                this.$data.noResults = true
                this.$data.allLoadedSearch = true
            }

            if (this.$data.itemsPerPageSearch >= this.$data.resultsAll.length) {
                this.$data.allLoadedSearch = true;
            }

            this.$data.results = this.$data.resultsAll.splice(0, 6)
        
        }
    },

    apollo: {

        event: {
            query: gql`query ($eventId: ID!) {
                event(eventId: $eventId) {
                    products {
                        nodes {
                            bottleImage {
                                fluid(maxWidth: 420, maxHeight:560, crop: PAD){
                                    src
                                }
                            }
                            name
                            shortName
                            slug
                            productId
                            producer {
                                name
                                exhibitorId
                            }

                            productCategory {
                                name
                            }

                            ... on Wine {
                                alcohol
                                country
                                region
                                subregion
                                otherAwards
                                description
                                wineColor
                                wineType
                                designation
                                vintage
                                
                                tastingNotes {
                                    note
                                }

                                grapeVarieties {
                                    varietyName
                                }

                                awards {
                                    award
                                    medal
                                }
                            }
                        }
                    }
                }
            }`,

            skip() {
                return !this.wineListId;
            },

            variables() {
                return {
                    itemsPerPage: this.itemsPerPage,
                    eventId: this.wineListId
                }
            },

            result ({ data, loading }) {

                if (!loading) { 
                    var items = data.event.products.nodes

                    /* Randomize array in-place using Durstenfeld shuffle algorithm */
                    for (var i = items.length - 1; i > 0; i--) {
                        var j = Math.floor(Math.random() * (i + 1));
                        var temp = items[i];
                        items[i] = items[j];
                        items[j] = temp;
                    }

                    console.log(items.slice(0, 6));

                    items = items.filter(function( obj ) {
                        return obj.bottleImage !== null;
                    });

                    items = items.filter(function( obj ) {
                        return obj.description !== null;
                    });

                    this.productsAll = data.event.products.nodes
                    this.products = items.slice(0, this.itemsPerPage)
                    this.isFetching = false
                    this.fetched = true
                }
            },
        }

    }
    

}
</script>