<template>
    <div class="live-feed-daily">
        <div class="daily-wrap">
            <SectionHeader v-bind:title="title"/>
            <div class="daily-container">
                <!-- <div class="player-splash">
                    <img src="https://via.placeholder.com/1920x1080" alt="" class="thumbnail">
                    <button class="join-meeting join-meeting--past">WATCH PAST EVENT</button>
                </div> -->

                <div id="videoContainer" class="post-video">
                    <video id="video" poster="" preload="metadata">
                        <source :src="this.videoUrl" type="video/mp4">
                    </video>

                    <button id="playLarge" type="button">
                        <img src="@/assets/img/video/icon_play.svg" alt="Play">
                    </button>

                    <button id="pauseLarge" type="button">
                        <img src="@/assets/img/video/icon_pause.svg" alt="Play Pause">
                    </button>

                    <div id="video-controls" class="controls">
                        <button id="playpause" type="button">
                            <img id="playButton" src="@/assets/img/video/icon_play.svg" alt="Play Pause">
                            <img id="pauseButton" src="@/assets/img/video/icon_pause.svg" alt="Pause">
                        </button>

                        <input id="progress" type="range" step="0.01" value="0">

                        <button id="mute" type="button">
                            <img id="muteButton" src="@/assets/img/video/icon_mute.svg" alt="Mute">
                            <img id="unmuteButton" src="@/assets/img/video/icon_unmute.svg" alt="Unmute">
                        </button>

                        <input id="volume" type="range" min="0" max="1" step="0.01" value="0.5">

                        <button id="fs" type="button">
                            <img src="@/assets/img/video/icon_fullScreen.svg" alt="Fullscreen">
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="chat-wrap">
            <SectionHeader v-bind:title="titleChat"/>
            <Chat v-bind:callObject="callObject"/>
        </div> -->
    </div>
</template>
<script>
import SectionHeader from '@/components/snippets/SectionHeader.vue'
import $ from 'jquery';

export default {
    name: 'LiveFeedDAily',
    components: { 
        SectionHeader,
    },

    props: {
        callObject: Object,
        videoUrl: String,
    },

    data() {
        return {
            title: "Past Event",
            titleChat: "Chat",
        }
    },

    mounted() {
        var thumbnail = $('.image-banner > img').attr('src');
        $('#video').attr('poster', thumbnail);

        // Video Player

    var supportsVideo = !!document.createElement('video').canPlayType;

    if (supportsVideo && $('#video').length) {
        // set up custom controls
        var videoContainer = document.getElementById('videoContainer');
        var video = document.getElementById('video');

        if ($('#podcastThumbnail').length) {
            var thumb = document.getElementById('podcastThumbnail');
        }

        var videoStarted = false;

        // Hide the default controls and lower volume
        video.controls  = false;
        video.volume    = 0.5;

        var playpause = document.getElementById('playpause');
        var playLarge = document.getElementById('playLarge');
        var pauseLarge = document.getElementById('pauseLarge');
        var mute = document.getElementById('mute');
        var volume = document.getElementById('volume');
        var progress = document.getElementById('progress');
        var fullscreen = document.getElementById('fs');

        console.log("progress ");
        console.log(progress);

        // Basic Controls

        playpause.addEventListener('click', function() {
            if (video.paused || video.ended) {
                video.play();
                $('#playButton').fadeOut(300);
                $('#pauseButton').fadeIn(300);
                $('#playLarge').fadeOut(300);
            }
            else {
                video.pause();
                $('#playButton').fadeIn(300);
                $('#pauseButton').fadeOut(300);
                $('#playLarge').fadeIn(300);
                $('#pauseLarge').fadeOut(300);
            } 
        });

        video.addEventListener('click', function() {
            if (video.paused || video.ended) {
                video.play();
                $('#playButton').fadeOut(300);
                $('#playLarge').fadeOut(300);
                $('#pauseButton').fadeIn(300);

                updateProgress();

                videoStarted = true;
            }
            else {
                video.pause();
                $('#playButton').fadeIn(300);
                $('#pauseButton').fadeOut(300);
                $('#playLarge').fadeIn(300);
                $('#pauseLarge').fadeOut(300);
            } 
        });

        if ($('#podcastThumbnail').length) {
            thumb.addEventListener('click', function() {
                if (video.paused || video.ended) {
                    video.play();
                    $('#playButton').fadeOut(300);
                    $('#playLarge').fadeOut(300);
                    $('#pauseButton').fadeIn(300);

                    updateProgress();
    
                    videoStarted = true;
                }
                else {
                    video.pause();
                    $('#playButton').fadeIn(300);
                    $('#pauseButton').fadeOut(300);
                    $('#playLarge').fadeIn(300);
                } 
            });

            $('#video-controls').css('opacity', 1)

            // thumb.addEventListener('mousemove', function(e) {
            //     (videoStarted) ? $('#video-controls').css('opacity', 1) : false;
                
            //     if (!video.paused) {
            //         $('#pauseLarge').fadeIn(300);
            //     }
            // });
    
            // thumb.addEventListener('mouseleave', function(e) {
            //     $('#video-controls').css('opacity', 0);
            //     $('#pauseLarge').fadeOut(300);
            // });
        }



        mute.addEventListener('click', function() {
            if (video.muted) {
                video.muted = false;
                $('#unmuteButton').fadeOut(300);
                $('#muteButton').fadeIn(300);
            } else {
                video.muted = true;
                $('#unmuteButton').fadeIn(300);
                $('#muteButton').fadeOut(300);
            }
        });

        playLarge.addEventListener('click', function() {
            video.play();
            $('#playLarge').fadeOut(300);

            $('#playButton').fadeOut(300);
            $('#pauseButton').fadeIn(300);

            updateProgress();

            videoStarted = true;

        });

        pauseLarge.addEventListener('click', function() {
            video.pause();
            $('#pauseLarge').fadeOut(300);

            $('#pauseButton').fadeOut(300);
            $('#playButton').fadeIn(300);

            videoStarted = true;
        });


        // Controls Fade

        videoContainer.addEventListener('mousemove', function() {
            (videoStarted) ? $('#video-controls').css('opacity', 1) : false;
            
            if (!video.paused) {
                $('#pauseLarge').fadeIn(300);
            }
        });

        videoContainer.addEventListener('mouseleave', function() {
            $('#video-controls').css('opacity', 0);
            $('#pauseLarge').fadeOut(300);
        });

        // Volume






        // Fullscreen Support

        var fullScreenEnabled = !!(document.fullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled || document.webkitSupportsFullscreen || document.webkitFullscreenEnabled || document.createElement('video').webkitRequestFullScreen);

        if (!fullScreenEnabled) {
            fullscreen.style.display = 'none';
        }

        fullscreen.addEventListener('click', function() {
            handleFullscreen();
        });

        var handleFullscreen = function() {
            if (isFullScreen()) {
               if (document.exitFullscreen) document.exitFullscreen();
               else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
               else if (document.webkitCancelFullScreen) document.webkitCancelFullScreen();
               else if (document.msExitFullscreen) document.msExitFullscreen();
               setFullscreenData(false);
            }
            else {
               if (videoContainer.requestFullscreen) videoContainer.requestFullscreen();
               else if (videoContainer.mozRequestFullScreen) videoContainer.mozRequestFullScreen();
               else if (videoContainer.webkitRequestFullScreen) videoContainer.webkitRequestFullScreen();
               else if (videoContainer.msRequestFullscreen) videoContainer.msRequestFullscreen();
               setFullscreenData(true);
            }
        }

        var isFullScreen = function() {
            return !!(document.fullscreen || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement || document.fullscreenElement);
        }

        var setFullscreenData = function(state) {
            videoContainer.setAttribute('data-fullscreen', !!state);
        }

        document.addEventListener('fullscreenchange', function() {
            setFullscreenData(!!(document.fullscreen || document.fullscreenElement));
        });
        document.addEventListener('webkitfullscreenchange', function() {
            setFullscreenData(!!document.webkitIsFullScreen);
        });
        document.addEventListener('mozfullscreenchange', function() {
            setFullscreenData(!!document.mozFullScreen);
        });
        document.addEventListener('msfullscreenchange', function() {
            setFullscreenData(!!document.msFullscreenElement);
        });
    }

    // Progress Bar

    function updateProgress() {
        
        video.ontimeupdate = function() {
            progress.value = video.currentTime;
        };

        progress.setAttribute('max', video.duration);

        progress.oninput = function() {
            video.currentTime = progress.value;
        };

        volume.oninput = function() {
            video.volume = volume.value;
        };

    }

    },
    

}
</script>